<template>
  <div
    v-if="tableData && Object.keys(tableData).length !== 0"
    ref="tableWrapper"
    :tabindex="tableId"
    :class="vCentred ? 'vcentred' : ''"
  >
    <v-row>
      <v-col>
        <v-tooltip
          v-if="exportTable"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              :color="'blue-grey lighten-3'"
              icon
              text
              v-on="on"
              @click="exportTableToExcel('table-' + tableId)"
            >
              <v-icon
                large
              >
                mdi-table-arrow-right
              </v-icon>
            </v-btn>
          </template>
          <span>Экспорт данных в Excel</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <div class="view">
        <div
          id="table-scroll"
          ref="wrapperWidth"
          class="table-wrapper"
        >
          <table
            :id="'table-' + tableId"
            :ref="'table-' + tableId"
            class="cell-border"
            :style="colsInPx.state ? 'width:' + colsInPx.width + 'px' : 'width:100%'"
          >
            <tr v-if="colFilterItems">
              <template v-for="(col, cindx) in cols">
                <td
                  :key="'col-head-1-' + cindx"
                  style="background: none!important; border: none!important;"
                  class="t-header"
                >
                  <v-autocomplete
                    v-if="colFilterItems[cindx]"
                    v-model="colFilter[cindx]"
                    :items="colFilterItems[cindx]"
                    outlined
                    dense
                    chips
                    small-chips
                    :label="col.id"
                    multiple
                    clearable
                  ></v-autocomplete>
                </td>
              </template>
            </tr>
            <tr>
              <template v-for="(col, cindx) in cols">
                <td
                  :key="'col-head-1-' + cindx"
                  style="background: none!important; border: none!important;"
                  class="t-header"
                >
                  <div v-if="tableData[rowsData[0]][col.id] && tableData[rowsData[0]][col.id].statusid === 1">
                    <v-btn
                      text
                      icon
                      @click="acceptTally(tableData[rowsData[0]][col.id], col.id)"
                    >
                      <v-icon color="blue">
                        mdi-checkbox-marked-circle
                      </v-icon>
                    </v-btn>
                  </div>
                </td>
              </template>
            </tr>
            <tr>
              <template v-for="(col, cindx) in cols">
                <td
                  :key="'col-head-' + cindx"
                  :style="col.colstyles"
                  class="t-header"
                >
                  <div>
                    {{ col.title }}
                  </div>
                </td>
              </template>
            </tr>
            <template v-for="(row, rindx) in rowsData">
              <tr
                v-if="!colFilterUnited || colFilterUnited.includes(row)"
                :key="'col-row-' + rindx"
              >
                <template v-for="(col, cindx) in cols">
                  <td
                    :key="'col-' + col.id + '-row-' + rindx"
                    :id="'cell-' + tableId + '-' + cindx + '-' + rindx"
                    :style="col.colstyles"
                    style="height: 13px"
                    :class="chosenClass(cindx,rindx)"
                    @click="setSelected(cindx, rindx, $event)"
                  >
                    <div
                      v-if="canEdit && selectedCell"
                      :style="`height: ${getRowHeight(index)};`"
                      style="height: 100%; width: 100%; overflow: hidden"
                    >
                      <input
                        autofocus
                        :value="item[col.value].main_value"
                        class="cell-input withoutbg"
                        @blur="inputOut(col.itemfield ? tableData[row][col.id][col.itemfield] : tableData[row][col.id])"
                      >
                    </div>
                    <div
                      v-else
                      style="width: 100%; height: 100%"
                    >
                      {{ col.itemfield ? tableData[row][col.id] ? tableData[row][col.id][col.itemfield] : '' : tableData[row][col.id] }}
                    </div>
                  </td>
                </template>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  function fallbackCopyTextToClipboard (text) {
    const textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')
      const msg = successful ? 'successful' : 'unsuccessful'
      console.log('Fallback: Copying text command was ' + msg)
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
  }

  export default {
    name: 'SimplTable',
    components: {
      // draggable: () => import('vuedraggable'),
    },
    props: {
      tableData: {
        type: Object,
        default: () => null,
      },
      tableId: {
        type: Number,
        default: 1,
      },
      cols: {
        type: Array,
        default: () => [],
      },
      rows: {
        type: Array,
        default: () => [],
      },
      exportTable: {
        type: Boolean,
        default: false,
      },
      print: {
        type: Boolean,
        default: false,
      },
      vCentred: {
        type: Boolean,
        default: false,
      },
      presentation: {
        type: Boolean,
        default: false,
      },
      canEdit: {
        type: Boolean,
        default: false,
      },
      tableTitle: {
        type: String,
        default: null,
      },
      rightDebug: {
        type: Boolean,
        default: false,
      },
      rightAdmin: {
        type: Boolean,
        default: false,
      },
      rightAdvanced: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        colFilter: {},
        delimiter: ';',
        truncateTable: false,
        importCSV: false,
        csvFile: null,
        parents: null,
        levels: null,
        maxLevel: 0,
        rowGroups: null,
        colSortDir: {},
        contextMenu: [
          {
            title: 'копировать',
            action: () => this.copyToBuffer(),
            disabled: false,
          },
          {
            title: 'вставить',
            action: () => this.pasteFromBuffer(),
            disabled: !this.canEdit,
          },
          {
            title: 'очистить',
            action: () => this.clearCell(),
            disabled: !this.canEdit,
          },
          {
            title: 'cкрыть ячейку',
            action: () => this.cellSetHideState(),
            hide: !(this.canEdit && this.rightadvanced),
          },
          {
            title: 'показать ячейку',
            action: () => this.cellSetShowState(),
            hide: !(this.canEdit && this.rightadvanced),
          },
          {
            title: 'показать формулу',
            action: () => this.cellShowMagicFormula(),
            hide: !this.rightdebug,
          },
        ],
        contextSubMenu: [
          {
            title: 'автосумма',
            elem: [
              {
                title: 'сумма',
                action: () => this.autoSumSelectedCells('sum'),
              },
              {
                title: 'среднее',
                action: () => this.autoSumSelectedCells('average'),
              },
              {
                title: 'максимум',
                action: () => this.autoSumSelectedCells('max'),
              },
              {
                title: 'минимум',
                action: () => this.autoSumSelectedCells('min'),
              },
              {
                title: 'счетчик',
                action: () => this.autoSumSelectedCells('counter'),
              },
            ],
          },
        ],
        showMenu: false,
        x: 0,
        y: 0,
        tableInitData: null,
        showFilter: false,
        snapshotDialog: false,
        snapshotlist: [],
        tableSnap: {},
        showInfoValue: null,
        dev: true,
        objectsTitle: null,
        popupItem: null,
        popupItemValueSnap: null,
        objectsAddDialog: false,
        objectsDialog: false,
        rowsToDelete: {},
        virtualObject: {
          id: null,
          title: null,
          show: false,
        },
        selectedCell: null, // null,
        selectedCellInput: true,
        chosenCell: null,
        rightButtonChoosen: null,
        valueСSnap: null,
        valueSnap: null,
        tableFix: false,
        modalsOpen: false,
        cellFormula: {
          show: false,
          data: null,
        },
        tableWidth: 0,
        tableLeft: 88,
        selectDynamicDate: [],
        selectPrefilter: [],
        isHoveringContextItem: false,
        groupIcon: 'mdi-chevron-up',
        autoSumContextActive: false,
      }
    },
    computed: {
      ...mapGetters(['tableStyles', 'plTablesChanges', 'getObjectsList', 'tableFilters']),
      colFilterItems () {
        let colFilterItems = null
        this.cols.forEach((col, cindex) => {
          if (col.filtered) {
            if (!colFilterItems) colFilterItems = {}
            colFilterItems[cindex] = []
            const colFilterItemsTemp = {}
            for (const row in this.tableData) {
              if (!colFilterItemsTemp[this.tableData[row][col.id][col.itemfield]]) {
                colFilterItemsTemp[this.tableData[row][col.id][col.itemfield]] = []
              }
              colFilterItemsTemp[this.tableData[row][col.id][col.itemfield]].push(row)
            }
            for (const item in colFilterItemsTemp) {
              console.log('ss', colFilterItemsTemp)
              console.log('itemfield', item)
              colFilterItems[cindex].push({ text: item, value: colFilterItemsTemp[item] })
            }
          }
        })
        return colFilterItems
      },
      colFilterUnited () {
        if (Object.keys(this.colFilter).length === 0 && this.colFilter.constructor === Object) return null
        let unArray = null
        for (const col in this.colFilter) {
          if (this.colFilter[col].length !== 0) {
            let colFiAll = []
            this.colFilter[col].forEach((filt) => {
              colFiAll = [...filt, ...colFiAll]
            })
            if (!unArray) {
              unArray = colFiAll
            } else {
              unArray = colFiAll.filter(x => unArray.includes(x))
            }
          }
        }
        return unArray
      },
      rowsData () {
        if (this.rows && this.rows.length > 0) {
          return this.rows
        }
        if (this.tableData && Object.keys(this.tableData).length !== 0) {
          return Object.keys(this.tableData).sort()
        } else {
          return {}
        }
      },
      iconCols () {
        if (this.likesAllCols) return 'mdi-close-box'
        if (this.likesSomeCols) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      showIfNotPrint () {
        if (this.print) {
          return false
        } else {
          const printRoute = ['/reportPdfGen']
          return !printRoute.includes(this.$route.path)
        }
      },
      haveDelRow () {
        return false
      },
      listNotStatic () {
        if (this.popupItem?.pldcolid) {
          const col = this.cols.find(x => x.plcolid === this.popupItem.pldcolid)
          if (col.pllistflgeditable == 1) {
            return false
          } else {
            return true
          }
        }
        return false
      },
      objectsList () {
        if (!this.popupItem) return {}
        const objList = this.$store.getters.getObjectsList[this.popupItem.colpllistid].data
        if (objList) {
          objList.forEach((item) => {
            item.searchString = item.title + ' (id:' + item.objectid + ')'
          })
          return objList
        } else {
          return {}
        }
      },
      objectsListInfo () {
        if (!this.popupItem) return {}
        const objList = this.$store.getters.getObjectsList[this.popupItem.colpllistid].info
        if (objList) {
          return objList
        } else {
          return {}
        }
      },
      tableChange () {
        let haveChange = false
        if (this.plTablesChanges[this.tableId]) {
          Object.keys(this.plTablesChanges[this.tableId]).forEach((x) => {
            if (this.plTablesChanges[this.tableId][x] === true) {
              haveChange = true
            }
          })
        }
        return haveChange
      },
      tableStyles () {
        return this.$store.getters.tableStyles
      },
      headersHaveGroup () {
        const haveGroup = this.cols.find(x => x.grouptitle)
        return !!haveGroup
      },
      headersGroups () {
        const cols = []
        const groups = {}
        if (this.headersHaveGroup) {
          this.cols.forEach((col, index) => {
            if (col.grouptitle) {
              if (groups[col.grouptitle] && !(!col.flgpresentvisible && this.presentation)) {
                groups[col.grouptitle]++
              } else if (!(!col.flgpresentvisible && this.presentation)) {
                groups[col.grouptitle] = 1
                cols.push({
                  value: col.id,
                  colstyles: col.colstyles,
                  width: col.width,
                  text: col.grouptitle,
                  grouptitle: col.grouptitle,
                  colIndex: index,
                })
              }
            } else {
              cols.push({
                value: col.id,
                text: col.title,
                colstyles: col.colstyles,
                rowSpan: 2,
                width: col.width,
                colIndex: index,
              })
            }
          })
        }
        return { cols: cols, groups: groups }
      },
      headersCols () {
        const cols = []
        if (this.headersHaveGroup) {
          this.cols.forEach((col, index) => {
            if (col.grouptitle && !(!col.flgpresentvisible && this.presentation)) {
              cols.push({
                value: col.id,
                text: col.title,
                colstyles: col.colstyles,
                width: col.width,
                grouptitle: col.grouptitle,
                colIndex: index,
                datatype: col.datatype,
              })
            }
          })
        }
        return cols
      },
      sCols () {
        if (this.chosenCell && this.chosenCell.colIndexEnd !== null) {
          return [this.chosenCell.colIndex, this.chosenCell.colIndexEnd].sort((a, b) => { return a - b })
        } else {
          return null
        }
      },
      sRows () {
        if (this.chosenCell && this.chosenCell.rowIndexEnd !== null) {
          return [this.chosenCell.rowIndex, this.chosenCell.rowIndexEnd].sort((a, b) => { return a - b })
        } else {
          return null
        }
      },
      colsInPx () {
        let colsPx = true
        let fullWidthPx = 0
        this.cols.forEach((col) => {
          if (col.width && col.width.includes('%')) {
            colsPx = false
          } else if (col.width && col.width.includes('px')) {
            fullWidthPx += col.width.replace('px')
          } else {
            colsPx = false
          }
        })
        return { state: colsPx, width: fullWidthPx }
      },
    },
    watch: {
      // rows () {
      //   let changes = 0
      //   const first = this.cols.find(x => x.numcol === 1)?.id || this.cols[0].id
      //   this.rows.forEach((y, indx) => {
      //     if ((JSON.stringify(y[first]) !== JSON.stringify(this.table.data[indx][first])) && !!this.table.row[indx].flgdynamic) {
      //       y[first].newsortindex = indx + 1
      //       if (y[first].status !== 'new') {
      //         y[first].status = 'reorder'
      //       }
      //       changes++
      //       this.$store.dispatch('setPlTablesChanges', {
      //         table: this.tableId,
      //         cell: 'reorder',
      //         changestate: true,
      //       })
      //     }
      //   })
      //   if (changes === 0) {
      //     this.$store.dispatch('setPlTablesChanges', {
      //       table: this.tableId,
      //       cell: 'reorder',
      //       changestate: false,
      //     })
      //   }
      // },
      showMenu (newVal) {
        if (!newVal) {
          setTimeout(() => { this.modalsOpen = false }, 100)
        }
      },
      activeTable: function (newVal) {
        if (newVal !== this.tableId) {
          this.chosenCell = null
          this.selectedCell = null
          window.removeEventListener('keydown', this.keyCommands)
        }
      },
      objectsDialog: function (newVal) {
        if (!newVal) {
          this.popupItem = null
          setTimeout(() => { this.modalsOpen = false }, 100)
        }
      },
      chosenCell: {
        handler () {
          this.autoSumContextActive = false
          if (this.chosenCell?.rowIndexEnd || this.chosenCell?.colIndexEnd) {
            this.autoSumContextActive = true
          }
        },
        deep: true,
      },
    },
    beforeDestroy () {
      if (this.chosenCell) {
        this.chosenCell = null
        this.selectedCell = null
        window.removeEventListener('keydown', this.keyCommands)
        this.$emit('changeActiveTable', null)
      }
      window.removeEventListener('scroll', this.scrollHandler)
      window.removeEventListener('resize', this.resizeHandler)
      window.removeEventListener('resize', this.recalcFixHeader)
      window.removeEventListener('resize', this.headerSpaceTable)
      this.cleanFixHeader()
    },
    methods: {
      acceptTally (item, colid) {
        if (item.statusid > 0) {
          this.$confirm({
            title: 'Утвердить список за ' + colid + '?',
            text: null,
            acceptText: 'Утвердить',
            cancelText: 'отмена',
          }).then(() => {
            const newstate = parseInt(item.statusid) + 1
            this.$store.dispatch('setTallyNewStatus', { spftsid: item.spftsid, newstatus: newstate }).then((resp) => {
              this.$store.dispatch('setSnackbar', { state: true, text: 'Отчет согласован.', color: 'success', direction: 'top center' })
              this.selectedTally.statusid = newstate
              this.selectedTallyLink.statusid = newstate
            }).catch((e) => {
              console.log(e)
            })
          }).catch(() => {
          })
        } else {
          console.log('go away')
        }
      },
      openListGroup () {
        this.$refs.listGroup.isActive = true
        this.groupIcon = 'mdi-chevron-down'
      },
      closeListGroup () {
        if (this.isHoveringContextItem) {
          this.$refs.listGroup.isActive = false
        }
        this.groupIcon = 'mdi-chevron-up'
      },
      isHoveringContextItemEnter () {
        this.isHoveringContextItem = true
        this.groupIcon = 'mdi-chevron-down'
      },
      isHoveringContextItemLeave () {
        this.isHoveringContextItem = false
      },
      copyHeaderTable () {
        if (this.$refs.fixedHeaderTr) {
          const copyTable = this.$refs.fixedHeaderTr.cloneNode(true)
          const children = copyTable.children
          for (const child of children) {
            if (child.classList.contains('collapse-col')) {
              child.addEventListener('click', this.showLevelEvent)
            }
          }
          this.$refs.pasteNodeElem.appendChild(copyTable)
          this.$refs.pasteNodeElem.classList.add('append-el')
        }
        if (this.$refs.fixedHeaderTr2 && this.$refs.fixedHeaderTr3) {
          const copyTable2 = this.$refs.fixedHeaderTr2.cloneNode(true)
          this.$refs.pasteNodeElem.appendChild(copyTable2)

          const copyTable3 = this.$refs.fixedHeaderTr3.cloneNode(true)
          this.$refs.pasteNodeElem.appendChild(copyTable3)
        }
      },
      headerSpaceTable () {
        if (this.$vuetify.breakpoint.width > 960) {
          this.tableLeft = 88
          return
        }
        this.tableLeft = 32
      },
      recalcFixHeader () {
        this.cleanFixHeader()
        if (this.$refs.pasteNodeElem) {
          this.$refs.pasteNodeElem.innerHTML = ''
        }
        this.copyHeaderTable()
      },
      cleanFixHeader () {
        if (!this.$refs.fixedHeaderTr) {
          return
        }
        const children = this.$refs.fixedHeaderTr.children
        for (const child of children) {
          if (child.classList.contains('collapse-col')) {
            child.removeEventListener('click', this.showLevelEvent)
          }
        }
      },
      showLevelEvent (event) {
        const curLvl = parseInt(event.target.innerText)
        this.showLevel(curLvl)
      },
      resizeHandler () {
        this.headerSpaceTable()
        this.tableWidth = this.$refs.wrapperWidth?.getBoundingClientRect().width
        this.$refs.tableCopy.style.width = this.colsInPx.state ? this.colsInPx.width + 'px' : `${this.tableWidth}px`
      },
      scrollHandler () {
        if (this.$refs.tableWrapper?.getBoundingClientRect().top < 110 && this.$refs.tableWrapper.getBoundingClientRect().bottom > 170) {
          this.$refs.tableWrapper.classList.add('table-wrapper')
        } else this.$refs.tableWrapper.classList.remove('table-wrapper')
        this.resizeHandler()
      },
      keyCommands (e) {
        // edit mode
        if (this.selectedCell) {
          switch (e.keyCode) {
            case 38: // upArrow
              if (!this.selectedCellInput) {
                e.preventDefault()
                this.selectedCell = null
                if (this.rowsData.length - 1 > this.chosenCell.rowIndex) {
                  do {
                    this.chosenCell.rowIndex = this.chosenCell.rowIndex - 1
                  } while (this.checkCollapse(this.chosenCell.rowIndex))
                }
              }
              break
            case 40: // downArrow
              if (!this.selectedCellInput) {
                e.preventDefault()
                this.selectedCell = null
                if (this.rowsData.length - 1 > this.chosenCell.rowIndex) {
                  this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
                }
              }
              break
            case 13: // enter
              e.preventDefault()
              this.selectedCell = null
              if (this.rowsData.length - 1 > this.chosenCell.rowIndex) {
                this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
              } else if (this.cols.length - 1 > this.chosenCell.colIndex) {
                this.chosenCell.rowIndex = 0
                this.chosenCell.colIndex = this.chosenCell.colIndex + 1
              }
              break
            case 37: // leftArrow
              if (!this.selectedCellInput) {
                e.preventDefault()
                this.selectedCell = null
                if (this.cols.length - 1 > this.chosenCell.colIndex) {
                  this.chosenCell.colIndex = this.chosenCell.colIndex - 1
                }
              }
              break
            case 39: // rightArrow
              if (!this.selectedCellInput) {
                e.preventDefault()
                this.selectedCell = null
                if (this.cols.length - 1 > this.chosenCell.colIndex) {
                  this.chosenCell.colIndex = this.chosenCell.colIndex + 1
                }
              }
              break
            case 9: // tab
              e.preventDefault()
              this.selectedCell = null
              if (this.cols.length - 1 > this.chosenCell.colIndex) {
                this.chosenCell.colIndex = this.chosenCell.colIndex + 1
              } else if (this.rowsData.length - 1 > this.chosenCell.rowIndex) {
                this.chosenCell.colIndex = 0
                this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
              }
              break
            case 27: // esc
              e.preventDefault()
              // this.rowsData[this.chosenCell.rowIndex][this.cols[this.chosenCell.colIndex].value].main_value = this.valueSnap
              this.selectedCell = null
              break
            default:
              break
          }
          // highlight mode
        } else if (!this.objectsDialog) {
          e.preventDefault()
          switch (e.keyCode) {
            case 8: // backspace
            case 46: // del
              this.clearCell()
              break
            case 13: // enter
              this.checkAndFocusCell(this.rows[this.chosenCell.rowIndex][this.cols[this.chosenCell.colIndex].value])
              break
            case 9: // tab
              if (this.cols.length - 1 > this.chosenCell.colIndex) {
                this.chosenCell.colIndex = this.chosenCell.colIndex + 1
              } else if (this.rows.length - 1 > this.chosenCell.rowIndex) {
                this.chosenCell.colIndex = 0
                this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
              }
              break
            case 37: // ArrowLeft
              if (this.chosenCell.colIndex > 0 && !e.shiftKey) {
                if (this.chosenCell.rowIndex < -1 && this.cols[this.chosenCell.colIndex].grouptitle !== '') {
                  const grouptitle = this.cols[this.chosenCell.colIndex].grouptitle
                  const colFromGroup = this.headersGroups.cols.find(x => x.grouptitle === grouptitle)
                  if (colFromGroup.colIndex !== 0) this.chosenCell.colIndex = colFromGroup.colIndex - 1
                } else {
                  this.chosenCell.colIndex = this.chosenCell.colIndex - 1
                }
                this.chosenCell.colIndexEnd = null
                this.chosenCell.rowIndexEnd = null
              } else {
                const colIndex = this.chosenCell.colIndexEnd !== null ? this.chosenCell.colIndexEnd : this.chosenCell.colIndex
                if (colIndex > 0 && e.shiftKey) {
                  if (this.chosenCell.rowIndexEnd !== null) {
                    this.chosenCell.colIndexEnd = colIndex - 1
                  } else {
                    this.chosenCell.colIndexEnd = colIndex - 1
                    this.chosenCell.rowIndexEnd = this.chosenCell.rowIndex
                  }
                }
              }
              break
            case 38: // ArrowUP
              console.log('upp')
              if (this.chosenCell.rowIndex > (this.headersHaveGroup ? -2 : -1) && !e.shiftKey) {
                do {
                  this.chosenCell.rowIndex = this.chosenCell.rowIndex - 1
                } while (!this.checkCollapse(this.chosenCell.rowIndex))
                this.chosenCell.colIndexEnd = null
                this.chosenCell.rowIndexEnd = null
                this.scrollToTable('cell-' + this.tableId + '-' + this.chosenCell.colIndex + '-' + this.chosenCell.rowIndex)
              } else {
                console.log('upp2')
                const rowIndex = this.chosenCell.rowIndexEnd !== null ? this.chosenCell.rowIndexEnd : this.chosenCell.rowIndex
                if (rowIndex > (this.headersHaveGroup ? 0 : -1) && e.shiftKey) {
                  if (this.chosenCell.rowIndexEnd !== null) {
                    do {
                      this.chosenCell.rowIndexEnd = rowIndex - 1
                    } while (!this.checkCollapse(this.chosenCell.rowIndexEnd))
                  } else {
                    this.chosenCell.colIndexEnd = this.chosenCell.colIndex
                    do {
                      this.chosenCell.rowIndexEnd = rowIndex - 1
                    } while (!this.checkCollapse(this.chosenCell.rowIndexEnd))
                  }
                  this.scrollToTable('cell-' + this.tableId + '-' + this.chosenCell.colIndexEnd + '-' + this.chosenCell.rowIndexEnd)
                }
              }
              break
            case 39: // ArrowRight
              if (this.cols.length - 1 > this.chosenCell.colIndex && !e.shiftKey) {
                if (this.chosenCell.rowIndex < -1 && this.cols[this.chosenCell.colIndex].grouptitle !== '') {
                  const grouptitle = this.cols[this.chosenCell.colIndex].grouptitle
                  const colFromGroup = this.headersGroups.cols.find(x => x.grouptitle === grouptitle)
                  const span = this.headersGroups.groups[grouptitle]
                  if (colFromGroup.colIndex !== 0) this.chosenCell.colIndex = colFromGroup.colIndex + span
                } else {
                  this.chosenCell.colIndex = this.chosenCell.colIndex + 1
                }
                this.chosenCell.colIndexEnd = null
                this.chosenCell.rowIndexEnd = null
              } else {
                const colIndex = this.chosenCell.colIndexEnd !== null ? this.chosenCell.colIndexEnd : this.chosenCell.colIndex
                if (colIndex < this.cols.length - 1 && e.shiftKey) {
                  if (this.chosenCell.rowIndexEnd !== null) {
                    this.chosenCell.colIndexEnd = colIndex + 1
                  } else {
                    this.chosenCell.colIndexEnd = colIndex + 1
                    this.chosenCell.rowIndexEnd = this.chosenCell.rowIndex
                  }
                }
              }
              break
            case 40: // ArrowDown
              if (this.rowsData.length - 1 > this.chosenCell.rowIndex && !e.shiftKey) {
                if (this.chosenCell.rowIndex < 0) {
                  if (this.cols[this.chosenCell.colIndex].grouptitle === '') {
                    this.chosenCell.rowIndex = 0
                  } else {
                    do {
                      this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
                    } while (!this.checkCollapse(this.chosenCell.rowIndex))
                    // this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
                  }
                } else {
                  do {
                    this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
                  } while (!this.checkCollapse(this.chosenCell.rowIndex))
                  // this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
                }
                this.chosenCell.colIndexEnd = null
                this.chosenCell.rowIndexEnd = null
                this.scrollToTable('cell-' + this.tableId + '-' + this.chosenCell.colIndex + '-' + this.chosenCell.rowIndex)
              } else {
                const rowIndex = this.chosenCell.rowIndexEnd !== null ? this.chosenCell.rowIndexEnd : this.chosenCell.rowIndex
                if (rowIndex < this.rowsData.length - 1 && e.shiftKey) {
                  if (this.chosenCell.rowIndexEnd !== null) {
                    do {
                      this.chosenCell.rowIndexEnd = rowIndex + 1
                    } while (!this.checkCollapse(this.chosenCell.rowIndexEnd))
                    // this.chosenCell.rowIndexEnd = rowIndex + 1
                  } else {
                    do {
                      this.chosenCell.rowIndexEnd = rowIndex + 1
                    } while (!this.checkCollapse(this.chosenCell.rowIndexEnd))
                    // this.chosenCell.rowIndexEnd = rowIndex + 1
                    this.chosenCell.colIndexEnd = this.chosenCell.colIndex
                  }
                  this.scrollToTable('cell-' + this.tableId + '-' + this.chosenCell.colIndexEnd + '-' + this.chosenCell.rowIndexEnd)
                }
              }
              break
            case 27: // esc
              this.chosenCell = null
              window.removeEventListener('keydown', this.keyCommands)
              break
            case 67: // 'c'
              if (e.ctrlKey) {
                // this.copyDataToClipboard
                this.copyToBuffer()
              } else {
                this.checkAndFocusCell(this.rows[this.chosenCell.rowIndex][this.cols[this.chosenCell.colIndex].value], e.key)
              }
              break
            case 86: // 'v'
              if (e.ctrlKey) {
                this.pasteFromBuffer()
              } else {
                this.checkAndFocusCell(this.rows[this.chosenCell.rowIndex][this.cols[this.chosenCell.colIndex].value], e.key)
              }
              break
            default:
              if (e.key.length === 1 && this.chosenCell) {
                this.checkAndFocusCell(this.rows[this.chosenCell.rowIndex][this.cols[this.chosenCell.colIndex].value], e.key, false)
              }
              break
          }
        }
      },
      sortData (column) {
        if (!this.table.info.flgdynamicrows) {
          return
        }
        const col = column.value
        if (!this.colSortDir[col]) {
          this.colSortDir = []
          this.colSortDir[col] = 'desc'
        } else if (this.colSortDir[col] === 'desc') {
          this.colSortDir = []
          this.colSortDir[col] = 'asc'
        } else {
          this.colSortDir = []
          this.colSortDir[col] = null
        }
      },
      collapse (parrentrow) {
        const state = this.rowGroups[parrentrow].status === 'o' ? 'c' : 'o'
        this.rowGroups[parrentrow].status = state
        this.rowGroups[parrentrow].child.forEach((rowId) => {
          if (this.rowGroups[rowId]) {
            if (state === 'c') {
              this.rowGroups[rowId].status = this.rowGroups[rowId].status + 'l'
            } else {
              this.rowGroups[rowId].status = this.rowGroups[rowId].status.replace('l', '')
            }
          }
        })
      },
      showLevel (level) {
        for (let lv = this.parents.length; lv > 0; lv--) {
          this.parents[lv - 1].forEach((rowId) => {
            this.rowGroups[rowId].status = lv + 1 > level ? 'c' : 'o'
          })
        }
      },
      checkCollapse (rowIndex) {
        if (!this.rowGroups) {
          return true
        } else if (this.table.row[rowIndex]) {
          const rowId = this.table.row[rowIndex].plgroupparentrowid
          if (this.rowGroups[rowId]) {
            return this.rowGroups[rowId].status === 'o'
          } else {
            return true
          }
        } else {
          return true
        }
      },
      uploadFile () {
        const payload = {
          pltabledataid: this.tableId,
          truncateTable: this.truncateTable,
          delimiter: this.delimiter,
          csvFile: this.csvFile,
        }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('importCSVFile', payload).then(() => {
          this.$store.commit('clearObjectList')
          const tablesIDs = []
          tablesIDs.push(this.tableId)
          this.$emit('updateTablesData', tablesIDs)
          this.csvFile = null
          this.importCSV = false
        }).catch((e) => {
          this.csvFile = null
          this.importCSV = false
          console.log('Error:', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      changeFileuploading () {
        this.csvFile = null
        this.importCSV = false
      },
      cellShowMagicFormula () {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('getMagicFormula', { pldataid: this.chosenCell.cellID }).then((resp) => {
          console.log('resp.length', resp.length)
          console.log('resp', resp)
          this.cellFormula.data = null
          if (resp.length > 0) {
            this.cellFormula.data = resp
            this.cellFormula.show = true
          } else if (resp.length == 0) {
            this.$store.dispatch('setSnackbar', { state: true, text: 'Нет формулы для данной ячейки', color: 'success', direction: 'top center' })
          }
          this.$store.dispatch('setDataLoading', false)
        }).catch(() => {
          this.$store.dispatch('setDataLoading', false)
        })
      },
      pasteFromBuffer () {
        if (!navigator.clipboard) {
          console.log('не могу до буфера достучатся')
          const successful = document.execCommand('paste')
          console.log(successful)
          return
        }
        navigator.clipboard.readText()
          .then(text => {
            console.log('clipboard', text)
            if (text.includes('\t') || text.includes('\n')) {
              const buffArray = text.replaceAll('\r', '').split('\n')
              const dataArray = []
              buffArray.forEach((row) => {
                if (row.length > 0) {
                  dataArray.push(row.split('\t'))
                }
              })
              let rowIndex = this.chosenCell.rowIndexEnd !== null ? this.sRows[0] : this.chosenCell.rowIndex
              const colIndex = this.chosenCell.colIndexEnd !== null ? this.sCols[0] : this.chosenCell.colIndex
              console.log(JSON.stringify(dataArray))
              dataArray.forEach((rw, rwi) => {
                rw.forEach((cl, cli) => {
                  while (!this.checkCollapse(rowIndex + rwi)) {
                    rowIndex++
                  }
                  if (rowIndex + rwi >= 0) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.tableData[this.rowsData[rowIndex + rwi]][this.cols[colIndex + cli].id][this.cols[colIndex + cli].itemfield] = cl
                  }
                })
              })
              this.chosenCell.rowIndex = rowIndex
              this.chosenCell.rowIndexEnd = rowIndex + dataArray.length - 1
              this.chosenCell.colIndex = colIndex
              this.chosenCell.colIndexEnd = colIndex + dataArray[0].length - 1
            } else {
              // eslint-disable-next-line vue/no-mutating-props
              this.tableData[this.rowsData[this.chosenCell.rowIndex]][this.cols[this.chosenCell.colIndex].id][this.chosenCell.colIndex.itemfield] = text
              // this.checkAndFocusCell(this.rows[this.chosenCell.rowIndex][this.cols[this.chosenCell.colIndex].value], text, false)
            }
            console.log('Pasted content: ', text)
          })
          .catch(err => {
            navigator.clipboard.read().then(cb => {
              console.log('cb', cb)
            })
            console.error('Failed to read clipboard contents: ', err)
          })
        navigator.clipboard.read().then(clipboardItems => {
          for (const clipboardItem of clipboardItems) {
            for (const type of clipboardItem.types) {
              if (type === 'text/html') {
                clipboardItem.getType(type).then(b => {
                  b.text().then(bt => {
                    console.log('bt', bt)
                  })
                })
              }
            }
          }
        })
      },
      copyToBuffer () {
        let text = ''
        let html = ''
        let colsgroup = ''
        let tablewidth = 0
        if (this.sCols) {
          for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
            const textid = `cell-${this.tableId}-${c}-0`
            const elem = document.getElementById(textid)
            const width = Math.round(getComputedStyle(elem.children[0]).width.replace('px', ''))
            colsgroup += `<col width="${width}" style="width:${Math.round(width * 0.75)}pt;">`
            tablewidth += width
          }
          for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
            let htmlRow = ''
            for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
              const textid = `cell-${this.tableId}-${c}-${r}`
              const elem = document.getElementById(textid)
              if (elem) {
                const tab = c === this.sCols[1] ? '' : '\t'
                const value = this.tableData[this.rowsData[r]][this.cols[c].id][this.cols[c].itemfield]
                text += value + tab
                const backgroundColor = getComputedStyle(elem.children[0]).backgroundColor
                const textAlign = getComputedStyle(elem.children[0]).textAlign
                const textVAlign = getComputedStyle(elem.children[0]).verticalAlign
                const wight = Math.round(getComputedStyle(elem.children[0]).width.replace('px', ''))
                const height = Math.round(getComputedStyle(elem.children[0]).height.replace('px', ''))
                const tdStyle = 'border: 0.5pt solid rgb(51,20,20);' +
                  `background-color: ${backgroundColor};` +
                  `width: ${Math.round(wight * 0.75)}pt;` +
                  `height: ${Math.round(height * 0.75)}pt;` +
                  `text-align: ${textAlign};` +
                  `vertical-align: ${textVAlign};`
                htmlRow += `<td height="${height}" style="${tdStyle}">${value}</td>`
              }
            }
            if (htmlRow != '') {
              html += '<tr>' + htmlRow + '</tr>'
              text += '\n'
            }
          }
          colsgroup = '<colgroup>' + colsgroup + '</colgroup>'
          html = '<table width="' + tablewidth + '" cellspacing="0" cellpadding="0" dir="ltr" border="1" style="table-layout: fixed; font-size: 10pt; font-family: Arial; width: 0px; border-collapse: collapse; width:' + Math.round(tablewidth * 0.75) + 'pt">' + colsgroup + '<tbody>' + html + '</tbody></table>'
        } else {
          const c = this.chosenCell.colIndex
          const r = this.chosenCell.rowIndex
          text = this.tableData[this.rowsData[r]][this.cols[c].id][this.cols[c].itemfield]
          const textid = `cell-${this.tableId}-${this.chosenCell.colIndex}-${this.chosenCell.rowIndex}`
          const elem = document.getElementById(textid)
          const backgroundColor = getComputedStyle(elem.children[0]).backgroundColor
          html = '<span  data-sheets-value="{' + text + '}" style="background-color: ' + backgroundColor + ';">' + text + '</span>'
        }
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text)
          return
        }
        // eslint-disable-next-line no-use-before-define,no-undef
        const data = [new ClipboardItem({ 'text/html': new Blob([html], { type: 'text/html' }), 'text/plain': new Blob([text], { type: 'text/plain' }) })]
        navigator.clipboard.write(data).then(() => {
          // this.$refs[]
          console.log('скопировано в буфер', html)
        })
      },
      autoSumSelectedCells (type) {
        const masValue = []
        let roundedArr = []
        let allElemIsPercent = false
        let maxNumberMask = 0
        let sumTable = 0
        if (this.sCols) {
          const masMask = []
          for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
            for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
              const textid = `cell-${this.tableId}-${c}-${r}`
              const elem = document.getElementById(textid)
              if (elem) {
                const cellType = this.rows[r][this.cols[c].value].main_datatype
                if (cellType === 'Float' || cellType === 'Integer' || this.rows[r][this.cols[c].value].datamask?.includes('p')) {
                  let value = r >= 0 ? this.rows[r][this.cols[c].value].main_value : this.cols[c].text
                  if (value && r >= 0 && this.rows[r][this.cols[c].value] && this.rows[r][this.cols[c].value].main_datatype !== 'String') {
                    value = value.replace('.', ',')
                  }
                  if (value) {
                    masValue.push(value)
                    masMask.push(this.rows[r][this.cols[c].value].datamask)
                  }
                }
              }
            }
          }
          allElemIsPercent = masMask.every(element => element.charAt(1) === 'p')
          for (let i = 0; i < masMask.length; i++) {
            const str = masMask[i]
            const number = parseInt(str.slice(-1))
            if (!isNaN(number)) {
              maxNumberMask = Math.max(maxNumberMask, number)
            }
          }

          roundedArr = masValue.map(num => parseFloat(num.replace(',', '.')))
        }
        if (type === 'sum' || type === 'average') {
          sumTable = roundedArr.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0)
        }
        let text = ''
        switch (type) {
          case 'sum':
            text = allElemIsPercent ? `Сумма равна: ${(sumTable * 100).toFixed(maxNumberMask)}%` : `Сумма равна: ${sumTable.toFixed(maxNumberMask)}`
            break

          case 'average': {
            const average = sumTable / masValue.length || 0
            text = allElemIsPercent
              ? `Среднее значение равно: ${(average * 100).toFixed(maxNumberMask)}%`
              : `Среднее значение равно: ${average.toFixed(maxNumberMask)}`
            break
          }
          case 'max': {
            const maxValue = roundedArr.length > 0 ? Math.max(...roundedArr.map(parseFloat)) : 0
            text = allElemIsPercent ? `Максимальное значение: ${(maxValue * 100).toFixed(maxNumberMask)}%` : `Максимальное значение: ${maxValue.toFixed(maxNumberMask)}`
            break
          }
          case 'min': {
            const minValue = roundedArr.length > 0 ? Math.min(...roundedArr.map(parseFloat)) : 0
            text = allElemIsPercent ? `Минимальное значение: ${(minValue * 100).toFixed(maxNumberMask)}%` : `Минимальное значение: ${minValue.toFixed(maxNumberMask)}`
            break
          }

          case 'counter':
            text = `Количество: ${masValue.length}`
            break

          default:
            text = 'Неверный тип'
            break
        }
        this.$store.commit('setSnackbarAutoSum', { state: true, text: text })
      },
      clearCell () {
        if (this.sCols) {
          for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
            for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
              this.checkAndPasteCell(this.rows[r][this.cols[c].value], null)
            }
          }
        } else {
          this.checkAndPasteCell(this.rows[this.chosenCell.rowIndex][this.cols[this.chosenCell.colIndex].value], null)
        }
      },
      cellSetHideState () {
        const changesStrings = []
        if (this.sCols) {
          for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
            for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
              changesStrings.push(this.rows[r][this.cols[c].value].id)
            }
          }
          console.log('cell', this.sCols)
        } else {
          changesStrings.push(this.rows[this.chosenCell.rowIndex][this.cols[this.chosenCell.colIndex].value].id)
        }
        const payload = {
          tableID: this.tableId,
          visibleIDString: null,
          hideIDString: changesStrings.join(','),
        }
        this.$store.dispatch('setTableDataVisible', payload).then((resp) => {
          if (resp[0].result === 1) {
            const initData = JSON.parse(this.tableInitData)
            if (this.sCols) {
              for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
                for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
                  initData[r][this.cols[c].value].flgrightsadvanced = 1
                }
              }
            }
            this.tableInitData = JSON.stringify(initData)
          }
        })
      },
      cellSetShowState () {
        const changesStrings = []
        if (this.sCols) {
          for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
            for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
              changesStrings.push(this.rows[r][this.cols[c].value].id)
            }
          }
          console.log('cell', this.sCols)
        } else {
          changesStrings.push(this.rows[this.chosenCell.rowIndex][this.cols[this.chosenCell.colIndex].value].id)
        }
        const payload = {
          tableID: this.tableId,
          visibleIDString: changesStrings.join(','),
          hideIDString: null,
        }
        this.$store.dispatch('setTableDataVisible', payload).then((resp) => {
          if (resp[0].result === 1) {
            const initData = JSON.parse(this.tableInitData)
            if (this.sCols) {
              for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
                for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
                  initData[r][this.cols[c].value].flgrightsadvanced = 0
                }
              }
            }
            this.tableInitData = JSON.stringify(initData)
          }
        })
      },
      showContext (item, colIndex, rowIndex, e) {
        e.preventDefault()
        this.modalsOpen = true
        if (this.chosenCell && this.chosenCell.colIndexEnd) {
          if (rowIndex < this.sRows[0] || rowIndex > this.sRows[1] || colIndex < this.sCols[0] || colIndex > this.sCols[1]) {
            this.chosenCell = {
              rowIndex: rowIndex,
              colIndex: colIndex,
              cellID: item.id,
              colIndexEnd: null,
              rowIndexEnd: null,
            }
          }
        } else {
          this.chosenCell = {
            rowIndex: rowIndex,
            colIndex: colIndex,
            cellID: item.id,
            colIndexEnd: null,
            rowIndexEnd: null,
          }
        }
        this.showMenu = false
        this.x = e.clientX + 10
        this.y = e.clientY + 5
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
      exportTableToExcel (tableID, filename = '') {
        const downloadLink = document.createElement('a')
        const dataType = 'application/vnd.ms-excel'
        const tableSelect = document.getElementById(tableID)
        const tableHTML = tableSelect.outerHTML.replace(/ /g, '%20')

        // Specify file name
        filename = filename ? filename + '.xls' : 'excel_data.xls'

        document.body.appendChild(downloadLink)

        if (navigator.msSaveOrOpenBlob) {
          const blob = new Blob(['\ufeff', tableHTML], { type: dataType })
          navigator.msSaveOrOpenBlob(blob, filename)
        } else {
          // Create a link to the file
          downloadLink.href = 'data:' + dataType + ', ' + '\uFEFF' + tableHTML
          // Setting the file name
          downloadLink.download = filename
          // triggering the function
          downloadLink.click()
        }
      },
      reverseChanges () {
        // eslint-disable-next-line vue/no-mutating-props
        this.table.data = null
        this.$nextTick(() => {
          // eslint-disable-next-line vue/no-mutating-props
          this.table.data = JSON.parse(this.tableInitData)
        })
        this.$store.commit('delPlTablesChanges', this.tableId)
      },
      chosenClass (col, row, rowspan, colspan) {
        if (!this.chosenCell) return ''
        if (this.sRows !== null) {
          if (this.sRows[0] > row || row > this.sRows[1]) return ''
          let CSSclass = ''
          if ((this.sCols[0] <= col && col <= this.sCols[1]) && (this.sRows[0] <= row && row <= this.sRows[1])) {
            CSSclass = CSSclass + ' chosen-in'
            if (this.sCols[0] === col) {
              CSSclass = CSSclass + ' chosen-left'
            }
            if (this.sRows[0] === row) {
              CSSclass = CSSclass + ' chosen-top'
            }
            if (this.sCols[1] === col) {
              CSSclass = CSSclass + ' chosen-right'
            }
            if (this.sRows[1] === row) {
              CSSclass = CSSclass + ' chosen-bottom'
            }
            if (this.chosenCell.colIndex === col && this.chosenCell.rowIndex === row) {
              CSSclass = CSSclass + ' chosen'
            }
            return CSSclass
          } else {
            return 'chosenRow'
          }
        } else {
          let cellClass = ''
          if (rowspan) {
            cellClass = this.chosenCell && this.chosenCell.colIndex === col && [-2, -1].includes(this.chosenCell.rowIndex) ? 'chosen' : cellClass
          } else if (colspan && this.chosenCell.rowIndex < 0) {
            const cols = []
            for (let i = 1; i <= colspan; i++) {
              cols.push(col + (i - 1))
            }
            cellClass = this.chosenCell && cols.includes(this.chosenCell.colIndex) && this.chosenCell.rowIndex === row ? 'chosen' : cellClass
          } else {
            cellClass = this.chosenCell && this.chosenCell.colIndex === col && this.chosenCell.rowIndex === row ? 'chosen' : cellClass
          }
          cellClass = this.chosenCell && this.chosenCell.colIndex !== col && this.chosenCell.rowIndex === row ? 'chosenRow' : cellClass
          return cellClass
        }
      },
      onClickOutside () {
        if ((this.selectedCell || this.chosenCell) && !this.modalsOpen) {
          this.selectedCell = null
          this.chosenCell = null
          window.removeEventListener('keydown', this.keyCommands)
        }
      },
      delDynTable (table) {
        this.$refs.dlgDel.open({
          title: 'Удалить таблицу?',
          acceptText: 'Удалить',
          cancelText: 'Отмена',
          dialogMaxWidth: 440,
        }).then(() => {
          this.$store.dispatch('setLoading', true)
          this.$store.dispatch('delDynTable', this.tableId).then(() => {
            this.$emit('delTable', this.tableId)
            this.$store.dispatch('setLoading', true)
          })
        })
      },
      addLineBreak (jsonValue) {
        const needVals = {
          id: jsonValue.id,
          mask: jsonValue.datamask,
          type: jsonValue.main_datatype,
          plcoldataid: jsonValue.plcoldataid,
          plrowdataid: jsonValue.plrowdataid,
          plcolid: jsonValue.pldcolid,
          plrowid: jsonValue.pldrowid,
          canedit: jsonValue.can_edit,
          foryear: jsonValue.foryear,
          formonth: jsonValue.formonth,
        }
        const lineBreakString = JSON.stringify(needVals, null, ' ')
        return lineBreakString
      },
      delVirtualObject (id) {
        this.$refs.dlgDel.open({
          title: 'Удалить объект?',
          acceptText: 'Продолжить',
          cancelText: 'Отмена',
          dialogMaxWidth: 440,
        }).then(() => {
          this.$store.dispatch('delVirtualObject', { pllistitemid: id, pllistid: this.popupItem.colpllistid }).then(() => {
            const objctIndex = this.objectsList.findIndex(x => x.listitemdataid === id)
            this.objectsList.splice(objctIndex, 1)
          })
        })
      },
      closeVirtualObjectDialog () {
        this.virtualObject = {
          id: null,
          show: false,
          title: null,
        }
      },
      wantDelRow (rowIndex) {
        this.$refs.dlgDel.open({
          title: 'Удалить строку?',
          acceptText: 'Удалить',
          cancelText: 'Отмена',
          dialogMaxWidth: 440,
        }).then(() => {
          this.delRow(rowIndex)
        })
      },
      delRow (rowIndex) {
        const tableindx = this.tableId
        const table = this.table
        const row = this.table.data[rowIndex]
        const [first] = Object.keys(row)
        if (row[first].status !== 'new') {
          if (this.rowsToDelete[tableindx]) {
            this.rowsToDelete[tableindx].push({
              plrowdataid: row[first].plrowdataid,
              sortindex: row[first].sortindex,
              status: 'del',
              data: [],
            })
          } else {
            this.rowsToDelete[tableindx] = []
            this.rowsToDelete[tableindx].push({
              plrowdataid: row[first].plrowdataid,
              sortindex: row[first].sortindex,
              status: 'del',
              data: [],
            })
          }
          this.$store.dispatch('setPlTablesChanges', { table: tableindx, cell: 'delRow', changestate: true })
        } else {
          let newRowCount = 0
          const firstCol = table.col.find(x => x.numcol === 1)?.id || this.cols[0].id
          table.data.forEach((row) => {
            if (row[firstCol].status === 'new') {
              newRowCount++
            }
          })
          if (newRowCount < 1) {
            this.$store.dispatch('setPlTablesChanges', { table: tableindx, cell: 'addRow', changestate: false })
          }
        }
        // eslint-disable-next-line vue/no-mutating-props
        this.table.data.splice(rowIndex, 1)
        // eslint-disable-next-line vue/no-mutating-props
        this.table.row.splice(rowIndex, 1)
        this.rowsToDelete = JSON.parse(JSON.stringify(this.rowsToDelete))
        // for (let i = 0; i < table.data.length; i++) {
        //   const first = table.col.find(x => x.numcol === 1)?.id || this.cols[0].id
        //   if (table.data[i][first].sortindex < 1000 && table.data[i][first].sortindex !== i + 1) {
        //     table.data[i][first].sortindex = i + 1
        //     table.data[i][first].main_value = i + 1
        //     if (table.data[i][first].status !== 'new') {
        //       table.data[i][first].status = 'upd'
        //     }
        //   }
        // }
      },
      checkAndPasteCell (item, newVal) {
        console.log('listVal newVal 1', newVal)
        console.log('listVal item 1', item)
        if (item.can_edit && this.canEdit && !item.colpllistid) {
          const vs = JSON.parse(JSON.stringify(item.main_value))
          console.log('listVal newVal 1', newVal)
          console.log('listVal item 1', item)
          if (newVal && item.main_datatype !== 'String') {
            if (newVal.includes('%')) {
              newVal = newVal.replace('%', '')
            }
            if (item.datamask && item.datamask.includes('p')) {
              newVal = newVal / 100
              newVal = newVal.toString()
            }
            newVal = newVal.replaceAll(' ', '')
            newVal = newVal.replaceAll(',', '.')
          }
          this.inputCheckInitValue(newVal, item)
          this.checkUpdate(item, vs)
          item.masked_value = this.mainValueFormatted(item)
        } else {
          console.log('listVal newVal', newVal)
          console.log('listVal item', item)
          const pllistid = item.colpllistid
          if (!(this.getObjectsList[pllistid] && this.getObjectsList[pllistid].data)) {
            console.log('noItem')
            this.$store.dispatch('getObjectList', pllistid).then(() => {
              const objId = this.getObjectsList[pllistid].data.find(x => x.title === newVal)
              if (objId) {
                console.log('objid', objId)
                this.setEditedAndObjectid(objId.objectid, pllistid, item)
              }
            })
          } else {
            const objId = this.getObjectsList[pllistid].data.find(x => x.title === newVal)
            if (objId) {
              console.log('objid', objId)
              this.setEditedAndObjectid(objId.objectid, pllistid, item)
            }
          }
          // check and update selected item this.openList(item)
        }
      },
      checkAndFocusCell (item, newVal = null, mouseinput = true) {
        if (item.can_edit && this.canEdit) {
          if (newVal && item.colpllistid) {
            console.log('listVal newVal', newVal)
            console.log('listVal item', item)
            const pllistid = item.colpllistid
            if (!(this.getObjectsList[pllistid] && this.getObjectsList[pllistid].data)) {
              console.log('noItem')
              this.$store.dispatch('getObjectList', pllistid).then(() => {
                const objId = this.getObjectsList[pllistid].data.find(x => x.title === newVal)
                if (objId) {
                  console.log('objid', objId)
                  this.setEditedAndObjectid(objId.objectid, pllistid, item)
                }
              })
            } else {
              const objId = this.getObjectsList[pllistid].data.find(x => x.title === newVal)
              if (objId) {
                console.log('objid', objId)
                this.setEditedAndObjectid(objId.objectid, pllistid, item)
              }
            }
            // check and update selected item this.openList(item)
          } else {
            if (newVal && item.main_datatype !== 'String') {
              if (newVal.includes('%')) {
                newVal = newVal.replace('%', '')
                // if (item.datamask && item.datamask.includes('p')) {
                //   newVal = newVal / 100
                //   newVal = newVal.toString()
                // }
              }
              console.log('newVaal', newVal)
              newVal = newVal.replaceAll(' ', '')
              newVal = newVal.replaceAll(',', '.')
            }
            const valmask = item.datamask ? item.datamask.substr(1, 1) : ''
            if (item.main_datatype === 'Integer') {
              if (item.main_value && item.main_value.includes('.')) {
                item.main_value = item.main_value.split('.')[0]
              }
            } else if (item.main_datatype === 'Float' && valmask === 'p') {
              if (item.main_value) {
                item.main_value = item.main_value * 100
              }
            } else if (item.main_datatype === 'Float') {
              if (item.main_value) {
                item.main_value = parseFloat(item.main_value)
              }
            }
            this.valueSnap = item.main_value
            this.selectedCell = item.id
            this.selectedCellInput = mouseinput
            this.tableFix = true
            if (newVal) {
              this.inputCheckInitValue(newVal, item)
            }
            this.$nextTick(() => {
              const refName = 'inputfield-' + item.id
              this.$refs[refName][0].focus()
            })
          }
        }
      },
      scrollToTable (cellID) {
        if (document.getElementById([cellID])) {
          const yOffset = -100
          const element = document.getElementById([cellID])
          if ((window.innerHeight - 10) < element.getBoundingClientRect().y) {
            const y = window.scrollY + Math.abs(yOffset)
            window.scrollTo({ behavior: 'smooth', top: y })
          } else if (element.getBoundingClientRect().y < Math.abs(yOffset)) {
            const y = window.scrollY + yOffset
            window.scrollTo({ behavior: 'smooth', top: y })
          }
        }
      },
      setSelected (colIndex, rowIndex, e) {
        if (e && (e.ctrlKey || e.shiftKey) && this.chosenCell) {
          this.chosenCell.colIndexEnd = colIndex
          this.chosenCell.rowIndexEnd = rowIndex
        } else {
          this.chosenCell = {
            rowIndex: rowIndex,
            colIndex: colIndex,
            cellID: null,
            colIndexEnd: null,
            rowIndexEnd: null,
          }
          window.addEventListener('keydown', this.keyCommands)
        }
      },
      checkEdit (item, colIndex, rowIndex, e) {
        if (!this.presentation) {
          if (!!item.can_edit && this.canEdit && this.chosenCell && this.chosenCell.colIndex === colIndex && this.chosenCell.rowIndex === rowIndex) {
            if (item.colpllistid) {
              this.openList(item)
            } else {
              const valmask = item.datamask ? item.datamask.substr(1, 1) : ''
              this.selectedCell = item.id
              if (item.main_datatype === 'Integer') {
                if (item.main_value && item.main_value.includes('.')) {
                  item.main_value = item.main_value.split('.')[0]
                }
              } else if (item.main_datatype === 'Float' && valmask === 'p') {
                if (item.main_value) {
                  item.main_value = item.main_value * 100
                }
              } else if (item.main_datatype === 'Float') {
                if (item.main_value) {
                  item.main_value = parseFloat(item.main_value)
                }
              }
              this.valueSnap = item.main_value
              this.tableFix = true
              this.$nextTick(() => {
                const refName = 'inputfield-' + item.id
                this.$refs[refName][0].focus()
              })
            }
          } else {
            if (e && (e.ctrlKey || e.shiftKey) && this.chosenCell) {
              this.chosenCell.colIndexEnd = colIndex
              this.chosenCell.rowIndexEnd = rowIndex
            } else {
              this.chosenCell = {
                rowIndex: rowIndex,
                colIndex: colIndex,
                cellID: item.id,
                colIndexEnd: null,
                rowIndexEnd: null,
              }
              window.addEventListener('keydown', this.keyCommands)
              this.$emit('changeActiveTable', this.tableId)
            }
          }
        }
      },
      setEditedAndObjectid (newval, colpllistid = null, item = null) {
        if (newval >= 0) {
          let valText = null
          if (colpllistid) {
            this.popupItem = item
            this.popupItem.colpllistid = colpllistid
            valText = this.getObjectsList[colpllistid].data.find(x => x.objectid === newval)
          } else {
            valText = this.objectsList.find(x => x.listitemdataid === newval)
          }
          this.popupItem.main_value = valText.title
          this.popupItem.masked_value = valText.title
        } else {
          this.popupItem.main_value = null
          this.popupItem.masked_value = null
        }
        if (this.popupItem && this.popupItem.status !== 'new' && this.popupItemValueSnap !== this.popupItem.main_value) {
          if (this.popupItem.status !== 'upd') {
            this.$store.dispatch('setPlTablesChanges', { table: this.tableId, cell: this.popupItem.id, changestate: true })
            this.popupItem.status = 'upd'
          }
        } else if (this.popupItemValueSnap === this.popupItem.main_value && this.popupItem.status !== 'upd') {
          this.$store.dispatch('setPlTablesChanges', { table: this.tableId, cell: this.popupItem.id, changestate: false })
          this.popupItem.status = null
        }
      },
      checkUpdate (value, valueSnap = this.valueSnap) {
        if (value.main_value === '-') { value.main_value = null }
        if (value && value.status !== 'new' && valueSnap !== value.main_value) {
          if (value.status !== 'upd') {
            this.$store.dispatch('setPlTablesChanges', { table: this.tableId, cell: value.id, changestate: true })
            value.status = 'upd'
          }
        } else if (valueSnap === value.main_value && value.status !== 'upd') {
          this.$store.dispatch('setPlTablesChanges', { table: this.tableId, cell: value.id, changestate: false })
          value.status = null
        }
        this.edit = false
      },
      canEditObject (id) {
        const vObj = this.objectsList.find(x => x.listitemdataid === id)
        return !!vObj.flgvirtual
      },
      openList (e) {
        if (e.can_edit === 0) {
          e.cancel = true
        } else if (e.colpllistid) {
          e.cancel = true
          const pllistid = e.colpllistid
          if (!(this.getObjectsList[pllistid] && this.getObjectsList[pllistid].data)) {
            this.$store.dispatch('getObjectList', e.colpllistid).then(() => {
              this.popupItem = e
              this.popupItemValueSnap = JSON.parse(JSON.stringify(e.main_value))
              this.objectsDialog = true
              this.modalsOpen = true
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$refs.selects.$refs.input.focus()
                  setTimeout(() => {
                    this.$refs.selects.activateMenu()
                  }, 100)
                })
              })
            })
          } else {
            this.popupItem = e
            this.popupItemValueSnap = JSON.parse(JSON.stringify(e.main_value))
            this.objectsDialog = true
            this.modalsOpen = true
          }
        }
      },
      addRow () {
        const newRow = {}
        let maxSortindex = 1
        let maxIndex = 0
        this.table.row.forEach((row, index) => {
          if (!!row.flgdynamic && maxSortindex < row.sortindex + 1) {
            maxSortindex = row.sortindex + 1
            maxIndex = index + 1
          }
        })
        console.log('maxSortIndex', maxSortindex)
        this.cols.forEach((col) => {
          newRow[col.id] = {
            status: 'new',
            id: null,
            objectid: null,
            plcoldataid: col.id,
            pldcolid: col.plcolid,
            plrowdataid: null,
            sortindex: maxSortindex,
            value_dbl: null,
            value_int: null,
            value_str: '',
            main_value: '',
            colpllistid: col.pllistdataid,
            can_edit: col.pllistdataid ? 1 : 0,
            can_del: 1,
          }
          if (col.numcol === 1) {
            newRow[col.id].main_value = maxSortindex
          }
          newRow[col.id].styles = this.table.info.rowstylebydef + ''
          if (col.colstyles) {
            newRow[col.id].styles = newRow[col.id].styles + ',' + col.colstyles
          }
        })
        const newRowItem = {
          id: null,
          title: null,
          sortindex: maxSortindex,
          can_del: 1,
          height: '40px',
          flgvisible: 1,
          flgnumerated: 1,
          flgdynamic: 1,
        }
        // eslint-disable-next-line vue/no-mutating-props
        this.table.data.splice(maxIndex, 0, newRow)
        // eslint-disable-next-line vue/no-mutating-props
        this.table.row.splice(maxIndex, 0, newRowItem)
        this.$store.dispatch('setPlTablesChanges', { table: this.tableId, cell: 'addRow', changestate: true })
      },
      showVirtualObjectDialog (id = null) {
        if (id) {
          const vObj = this.objectsList.find(x => x.listitemdataid === id)
          this.virtualObject.title = vObj.title
          this.virtualObject.id = vObj.objectid
          this.virtualObject.listdataid = vObj.listitemdataid
        } else {
          this.virtualObject.title = this.$refs.selects.lazySearch
        }
        this.virtualObject.show = true
      },
      updateVirtualObject () {
        if (this.virtualObject.id) {
          const payload = {
            pllistitemid: this.virtualObject.listdataid,
            title: this.virtualObject.title,
          }
          this.$store.dispatch('updateVirtualObject', payload).then((resp) => {
            this.$store.dispatch('getObjectList', this.popupItem.colpllistid).then(() => {
              this.closeVirtualObjectDialog()
              this.popupItem.pllistitemdataid = resp[0].result
              this.setEditedAndObjectid(this.popupItem.pllistitemdataid)
            })
          })
        } else {
          this.$store.dispatch('addVirtualObject', { pllistid: this.popupItem.colpllistid, title: this.virtualObject.title }).then((resp) => {
            this.$store.dispatch('getObjectList', this.popupItem.colpllistid).then(() => {
              this.closeVirtualObjectDialog()
              this.popupItem.pllistitemdataid = resp[0].listitemdataid
              this.setEditedAndObjectid(this.popupItem.pllistitemdataid)
            })
          })
        }
      },
      updateListObject () {
        const pi = JSON.parse(JSON.stringify(this.popupItem))
        const colIndex = this.cols.findIndex(c => c.id === pi.plcoldataid)
        const rowIndex = this.table.row.findIndex(r => r.id === pi.plrowdataid)
        this.chosenCell = {
          rowIndex: rowIndex,
          colIndex: colIndex,
          cellID: pi.id,
          colIndexEnd: null,
          rowIndexEnd: null,
        }
        this.objectsDialog = false
        this.popupItem = null
      },
      inputOut (value) {
        const valmask = value.datamask ? value.datamask.substr(1, 1) : ''
        if (value.main_datatype === 'Float' && valmask === 'p' && value.main_value) {
          value.main_value = value.main_value / 100
        }
        value.masked_value = this.mainValueFormatted(value)
        this.checkUpdate(value)
        this.selectedCell = null
      },
      saveAndRecalc (table) {
        this.$store.dispatch('setDataLoading', true)
        const tableDataUpdate = {
          versionid: table.info.plversionid,
          tabledataid: this.tableId,
          flgdynamic: table.info.flgdynamicrows,
          rows: [],
        }
        if (this.rowsToDelete[this.tableId]) {
          this.rowsToDelete[this.tableId].forEach((rowsDelete) => {
            tableDataUpdate.rows.push(rowsDelete)
          })
          this.rowsToDelete[this.tableId] = []
        }
        let first = this.cols.find(x => x.numcol === 1)
        let haveNumCol = false
        if (first) {
          first = first.id
          haveNumCol = true
        }
        table.data.forEach((row) => {
          if (!first) {
            [first] = Object.keys(row)
          }
          if (row[first].status && row[first].status === 'new') {
            const newRow = {
              plrowdataid: null,
              sortindex: row[first].sortindex,
              status: 'new',
              data: [],
            }
            for (const key in row) {
              if (row[key].main_value !== '' && (haveNumCol ? +key !== +first : true)) {
                const newCell = {
                  pldataid: null,
                  plcoldataid: row[key].plcoldataid,
                  objectid: row[key].pllistitemdataid,
                  pllistitemdataid: row[key].pllistitemdataid,
                  value: row[key].main_value,
                }
                if (row[key].maincol === 1) {
                  newCell.maincol = 1
                }
                newRow.data.push(newCell)
              }
            }
            tableDataUpdate.rows.push(newRow)
          } else if (row[first].status && row[first].status === 'reorder') {
            const rowInfoReorder = this.table.row.find((r) => r.id === row[first].plrowdataid)
            const newRow = {
              plrowdataid: row[first].plrowdataid,
              sortindex: row[first].newsortindex,
              status: 'upd',
              flgdynamic: rowInfoReorder.flgdynamic,
              data: [],
            }
            for (const key in row) {
              if (first !== key && row[key].status && row[key].status === 'upd') {
                const newCell = {
                  pldataid: null,
                  plcoldataid: row[key].plcoldataid,
                  objectid: row[key].pllistitemdataid,
                  pllistitemdataid: row[key].pllistitemdataid,
                  value: row[key].main_value,
                }
                newRow.data.push(newCell)
              }
            }
            tableDataUpdate.rows.push(newRow)
          } else {
            for (const key in row) {
              if (row[key].status && row[key].status === 'upd' && (haveNumCol ? +key !== +first : true)) {
                const rowid = tableDataUpdate.rows.findIndex((val) => val.plrowdataid === row[key].plrowdataid)
                const rowInfo = this.table.row.find((r) => r.id === row[key].plrowdataid)
                console.log('rowid', rowid)
                console.log('rowid', row[key])
                console.log('rowid', rowInfo)
                if (rowid < 0) {
                  const updateRow = {
                    plrowdataid: row[key].plrowdataid,
                    sortindex: row[key].sortindex,
                    status: 'upd',
                    flgdynamic: rowInfo.flgdynamic,
                    data: [],
                  }
                  updateRow.data.push({
                    pldataid: row[key].id,
                    plcoldataid: row[key].plcoldataid,
                    objectid: row[key].pllistitemdataid,
                    pllistitemdataid: row[key].pllistitemdataid,
                    value: row[key].main_value,
                  })
                  tableDataUpdate.rows.push(updateRow)
                } else {
                  tableDataUpdate.rows[rowid].data.push({
                    pldataid: row[key].id,
                    plcoldataid: row[key].plcoldataid,
                    objectid: row[key].pllistitemdataid,
                    pllistitemdataid: row[key].pllistitemdataid,
                    flgdynamic: rowInfo.flgdynamic,
                    value: row[key].main_value,
                  })
                }
              }
            }
          }
        })
        this.$emit('updateTableData', tableDataUpdate)
      },
      getRowHeight () {
        return '20px'
      },
      inputCheckInitValue (newValue, value) {
        if (value.pllistitemdataid) {
          console.log('check list item data')
        }
        if (value.main_datatype === 'Integer' || value.main_datatype === 'Float') {
          if (newValue && (!isNaN(newValue) || newValue === '-')) {
            value.main_value = newValue.replaceAll(' ', '')
          }
        } else if (value.main_datatype === 'String') {
          value.main_value = newValue
        }
        if (value && (newValue === '' || newValue === ' ' || newValue === null)) value.main_value = null
      },
      inputCheck (event, value) {
        const val = event
        if (val.target.value && val.target.value.length > 1 && val.target.value[0] == 0 && val.target.value[1] !== '.') { val.target.value = val.target.value.slice(1) }
        if (value.main_datatype === 'Integer') {
          const test = val.target.value.replace(/\s/g, '').match(/^-?\d+$/g)
          if (test || val.target.value.replace(/\s/g, '') === '-') {
            value.main_value = val.target.value.replace(/\s/g, '').replace(/^0+(?!$)/g, '')
          }
        } else if (value.main_datatype === 'Float') {
          // const roundingVol = this.value.datamask ? this.value.datamask.substr(4) || this.value.datamask.substr(2) : null
          const roundingExp = '*'
          const regExpString = `^[+-]?(([0-9]+([.,][0-9]${roundingExp})?)|([.,][0-9]${roundingExp}))$` // '^-?(?=.*[1-9])[0-9]*[.,]?[0-9]' + roundingExp + '$'
          const regexp = new RegExp(regExpString)
          const test = val.target.value.replace(/\s/g, '').match(regexp)
          if (test || val.target.value.replace(/\s/g, '') === '-') {
            value.main_value = val.target.value.replace(/\s/g, '').replace(/,/g, '.')
            // value.value_dbl = value.main_value
          }
        } else if (value.main_datatype === 'String') {
          value.main_value = val.target.value
        }
        if (value && (val.target.value === '' || val.target.value === ' ')) value.main_value = null
        this.$forceUpdate()
        val.target.value = value.main_value
      },
      numberWithSpaces (x) {
        if (x) {
          const parts = x.toString().split('.')
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          return parts.join('.')
        } else {
          return ''
        }
        // return x ? x.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''
      },
      addStyles (item) {
        let styleString = ''
        let innerStyle = ''
        const styleObj = {}
        const styles = this.presentation ? item.styles_presentation : item.styles
        let additionClass = null
        if (styles) {
          const styleArr = styles.split(',')
          styleArr.forEach((style) => {
            for (const key in this.tableStyles[style]) {
              if (key == 'pl-vertical-align') {
                if (!additionClass) additionClass = {}
                if (this.tableStyles[style][key] === 'middle') {
                  additionClass = 'v-middle'
                  styleObj.position = 'relative'
                  styleObj['vertical-align'] = 'middle'
                } else if (this.tableStyles[style][key] === 'bottom') {
                  additionClass = 'v-bottom'
                  styleObj.position = 'relative'
                }
              } else if (key == 'padding') {
                innerStyle = 'padding:' + this.tableStyles[style][key] + '!important'
              } else {
                styleObj[key] = this.tableStyles[style][key]
              }
            }
          })
          styleString = JSON.stringify(styleObj)
            .replace(/,/g, ';')
            .replace(/"/g, '')
            .replace('{', '')
            .replace('}', '')
        }
        return { styleString, innerStyle, additionClass }
      },
      compileHeight (rowspan, height) {
        let hString = ''
        if (rowspan) {
          hString = (height * 2) + 1
        } else {
          hString = height
        }
        return 'height: ' + hString + 'px;'
      },
      showSnapHistory (pltableid) {
        this.$store.dispatch('setLoading', true)
        this.$store.dispatch('getSnapshotsList', pltableid).then((resp) => {
          this.snapshotlist = resp
          this.$store.dispatch('setLoading', false)
          this.snapshotDialog = true
        })
      },
      showSnapshotInfo (plsnapshotid) {
        const routeData = this.$router.resolve({
          path: 'snapshotinfo',
          query: {
            pltableid: this.tableId,
            plsnapshotid: plsnapshotid,
          },
        })
        console.log('routeData', routeData)
        window.open(routeData.href, '_blank')
      },
    },
  }
</script>

<style lang="scss">
.t-header {
  background-color: #c6f0ff!important;
}
.view {
  margin: auto;
  width: 100%;
}
div.table-wrapper {
 overflow: auto;
  position: relative;
  white-space: nowrap;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
td,th {
  position: relative;
}
.table-wrapper td div, .table-wrapper th div {
  padding: 2px;
}
td.collapse-col div {
  background: none;
}
.cell-border tbody tr td.collapse-col {
  border: none!important;
}
.collapse-row {
  cursor: pointer;
  text-align: left!important;
  margin: 0px;
  height: 14px;
  width: 14px;
  border: 1px solid #3c4858
}
.triangle div {
  overflow: hidden;
  position: inherit;
}
.vcentred {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.hidden-row td:after {
  position: absolute;
  z-index: 1;
  text-align: center;
  left: 0;
  top: 0;
  width: 252%;
  height: .2em;
  content:"";
  background-color: rgba(255, 0, 0, .7);
}

table tr td.chosen-in:not(.chosen), table tr th.chosen-in:not(.chosen) {
  border: 1px solid #c9c9c9 !important;
  /*border: 1px solid #858585#c9c9c9 !important;*/
  /*box-shadow: inset 1px 1px 0px 1px #00b2f9;*/
}

table tr td.chosen-left:not(.chosen), table tr th.chosen-left:not(.chosen) {
  border-left: 1px double #00b2f9!important;
  /*box-shadow: inset 1px 1px 0px 1px #00b2f9;*/
}
/*table tr td.chosen-left:not(.chosen) div{*/
/*  border-left: 1px solid #00b2f9!important;*/
/*  !*box-shadow: inset 1px 1px 0px 1px #00b2f9;*!*/
/*}*/
table tr td.chosen-top:not(.chosen), table tr th.chosen-top:not(.chosen) {
  border-top: 1px double #00b2f9!important;
  /*box-shadow: inset 1px 1px 0px 1px #00b2f9;*/
}
/*table tr td.chosen-top:not(.chosen) div{*/
/*  border-top: 1px solid #00b2f9!important;*/
/*  !*box-shadow: inset 1px 1px 0px 1px #00b2f9;*!*/
/*}*/
table tr td.chosen-right:not(.chosen), table tr th.chosen-right:not(.chosen) {
  border-right: 1px solid #00b2f9!important;
}
/*table tr td.chosen-right:not(.chosen) div {*/
/*  border-right: 1px solid #00b2f9!important;*/
/*}*/
table tr td.chosen-bottom:not(.chosen), table tr tr th.chosen-bottom:not(.chosen) {
  border-bottom: 1px solid #00b2f9!important;
}

/*table tr td.chosen-bottom.chosen-right:not(.chosen):after {*/
/*  position: absolute;*/
/*  bottom: 0px;*/
/*  right: 0px;*/
/*  content: ' ';*/
/*  width: 5px;*/
/*  height: 5px;*/
/*  background-color: #00b2f9;*/
/*}*/
/*table tr td.chosen-bottom:not(.chosen) div{*/
/*  border-bottom: 1px solid #00b2f9!important;*/
/*}*/
table tr td.chosen, table tr th.chosen {
  border: 1px double #4793d5 !important;
}
table tr td.chosen div, table tr th.chosen div{
  box-shadow: inset 0px 0px 0px 1px #4793d5 !important;
}

/*table tr td.cell {*/
/*  box-shadow: inset 0px 0px 0px 1px #4793d5 !important;*/
/*}*/

table tr td.cell {
  border: none!important;
  padding: 0;
  margin: 0;
}
table tr td.cell input:focus {
  border: 0px solid #4793d5 !important;
  background: none;
}

.chosenRow:before {
  content: "";
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background: rgba(0,0,0,.05);
}
.canEdit {
  cursor: pointer;
}
.cell-input {
  width: 100%;
  height: 100%;
  padding-left: 4px;
}
.withoutbg {
  background: none;
}

.select-border {
  background-color: #2d6da3;
}
.header-cell {
  width: 100%;
  height: 100%;
}
table.cell-border {
  clear: both;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  overflow: auto;
}

.cell-border th {
  padding: 0!important;
}
/*.cell-border thead tr th:first-child, .cell-border tbody tr td:first-child {*/
/*  border-left: 1px solid rgba(0, 0, 0, 1);*/
/*}*/
/*.cell-border thead tr th {*/
/*  padding: 0!important;*/
/*}*/
.cell-border tr th, .cell-border tr td:not(.chosen) {
  border: 1px solid black!important;
  padding: 0!important;
  overflow: hidden;
}
/*.cell-border tbody td {*/
/*  border-top: 1px solid rgba(0, 0, 0, 1);*/
/*  border-right: 1px solid rgba(0, 0, 0, 1);*/
/*}*/

/*.list .row {*/
/*  display: flex;*/
/*}*/

revogr-viewport-scroll.col {
  padding: 0;
}
.checkit{
  background-color: salmon;
}
.row.fit {
  display: block;
  margin: 0;
  text-align: center;
}
</style>
