<template>
  <v-container
    id="widgets"
    fluid
    tag="section"
    class="pt-0"
  >
    <base-material-card
      color="success"
      inline
      class="px-5 py-3"
      filter
    >
      <template #heading>
        <div class="text-h3 font-weight-light">
          <v-icon>mdi-file-document-multiple-outline</v-icon> Сводный отчет
        </div>
      </template>
      <v-row class="mt-2">
        <v-col>
          <v-select
            v-model="DossiersOfficesSelect"
            class="pr-2"
            label="Подразделение:"
            :items="DossiersOffices"
            item-text="dossiersofficetitle"
            item-value="dossierofficeid"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-menu
            ref="menuFrom"
            v-model="menuFrom"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="dateFromF"
                persistent-hint
                @blur="parseDate(dateFromF)"
                v-on="on"
              >
              <span
                slot="prepend"
                class="flex text-h4 font-weight-light"
              >с
              </span>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dateFrom"
              no-title
              locale="ru-ru"
              @change="updateData()"
            />
          </v-menu>
        </v-col>
<!--        <v-col-->
<!--          cols="3"-->
<!--          class="pt-0"-->
<!--        >-->
<!--          <v-menu-->
<!--            ref="menuFrom"-->
<!--            v-model="menuFrom"-->
<!--            :close-on-content-click="false"-->
<!--            transition="scale-transition"-->
<!--            offset-y-->
<!--            max-width="290px"-->
<!--            min-width="290px"-->
<!--          >-->
<!--            <template #activator="{ on }">-->
<!--              <v-text-field-->
<!--                v-model="dateFromF"-->
<!--                persistent-hint-->
<!--                dense-->
<!--                :disabled="!DossiersOfficesSelect"-->
<!--                @blur="dateFrom = parseDate(dateFromF)"-->
<!--                v-on="on"-->
<!--              >-->
<!--                <span-->
<!--                  slot="prepend"-->
<!--                  class="flex text-h4 font-weight-light"-->
<!--                >с-->
<!--                </span>-->
<!--              </v-text-field>-->
<!--            </template>-->
<!--            <v-date-picker-->
<!--              v-model="dateFrom"-->
<!--              no-title-->
<!--              locale="ru-ru"-->
<!--              @input="menuFrom = false"-->
<!--            />-->
<!--          </v-menu>-->
<!--        </v-col>-->
<!--        <v-col-->
<!--          cols="3"-->
<!--          class="pt-0"-->
<!--        >-->
<!--          <v-menu-->
<!--            ref="menuTo"-->
<!--            v-model="menuTo"-->
<!--            :close-on-content-click="false"-->
<!--            transition="scale-transition"-->
<!--            offset-y-->
<!--            max-width="290px"-->
<!--            min-width="290px"-->
<!--          >-->
<!--            <template #activator="{ on }">-->
<!--              <v-text-field-->
<!--                v-model="dateToF"-->
<!--                persistent-hint-->
<!--                dense-->
<!--                :disabled="!DossiersOfficesSelect"-->
<!--                @blur="dateTo = parseDate(dateToF)"-->
<!--                v-on="on"-->
<!--              >-->
<!--                <span-->
<!--                  slot="prepend"-->
<!--                  class="flex text-h4 font-weight-light "-->
<!--                >по-->
<!--                </span>-->
<!--              </v-text-field>-->
<!--            </template>-->
<!--            <v-date-picker-->
<!--              v-model="dateTo"-->
<!--              no-title-->
<!--              locale="ru-ru"-->
<!--              @input="menuTo = false"-->
<!--            />-->
<!--          </v-menu>-->
<!--        </v-col>-->
        <v-col
          cols="2"
          class="pt-0"
        >
          <v-btn
            small
            color="primary"
            :disabled="!(dateTo && dateFrom && dateTo >= dateFrom && DossiersOfficesSelect)"
            @click="getPerformance()"
          >
            Применить
          </v-btn>
        </v-col>
<!--        <v-col-->
<!--          cols="3"-->
<!--          class="pt-0"-->
<!--        >-->
<!--          <v-select-->
<!--            v-model="groupCol"-->
<!--            :items="gCols"-->
<!--            item-text="name"-->
<!--            dense-->
<!--            return-object-->
<!--          />-->
<!--        </v-col>-->
      </v-row>
      <v-row>
        <v-col class="my-4">
          <table-class
            :table-data="sortTallysData2"
            :table-id="12"
            :cols="tableCols"
            :can-edit="true"
            :right-debug="true"
            :right-admin="true"
            :right-advanced="true"
            :show-table-title="true"
            :export-table="true"
          />
        </v-col>
      </v-row>
    </base-material-card>
    <data-loading />
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import tableClass from './components/tableClass.vue'
  // import simplTable from './components/simplTable.vue'
  import dataLoading from '../components/dataLoading'

  export default {
    name: 'SimplefoodsCons',
    components: {
      // simplTable,
      dataLoading,
      tableClass,
    },
    data () {
      return {
        groupCol: {
          name: 'Цех',
          colId: 'wstitle',
        },
        gCols: [
          {
            name: 'Цех',
            colId: 'wstitle',
          },
          {
            name: 'ФИО',
            colId: 'empfio',
          },
        ],
        Performs: [],
        PerformsGroup: {},
        dateFrom: null,
        dateTo: null,
        dateFromF: null,
        dateToF: null,
        // dateFrom: '2023-05-07', // null,
        // dateTo: '2023-05-11', // null,
        // dateFromF: '07-05-2023', // null,
        // dateToF: '11-05-2023', // null,
        menuFrom: false,
        menuTo: false,
        Tallys: [],
        TallysData: {},
        TallysData2: {},
        DossiersOffices: [],
        DossiersOfficesSelect: null,
      }
    },
    computed: {
      ...mapGetters({}),
      subGroup () {
        return this.groupCol.colId === 'wstitle' ? this.gCols[1] : this.gCols[0]
      },
      // sortTallysData () {
      //   if (Object.keys(this.TallysData).length === 0) return {}
      //   return JSON.parse(JSON.stringify(this.summOffsetTime(this.groupTallyData(this.TallysData, this.groupCol.colId))))
      // },
      sortTallysData2 () {
        if (Object.keys(this.TallysData).length === 0) return {}
        const newData = JSON.parse(JSON.stringify(this.summOffsetTime(this.groupTallyData(this.TallysData2, 'datedates'))))
        for (const key in newData) {
          newData[key]['Цех'] = { offsethourss: key.split('!!')[0] }
          newData[key]['ФИО'] = { offsethourss: key.split('!!')[1] }
          newData[key]['Личн. Номер.'] = { offsethourss: key.split('!!')[2] }
          newData[key]['Должность'] = { offsethourss: key.split('!!')[3] }
        }
        return newData
      },
      likeSPtable () {
        if (Object.keys(this.TallysData).length === 0) return {}
        const newData = JSON.parse(JSON.stringify(this.summOffsetTime(this.groupTallyData(this.TallysData2, 'datedates'))))
        for (const key in newData) {
          newData[key]['Цех'] = { offsethourss: key }
        }
        return newData
      },
      tableCols () {
        const cols = []
        const datefill = new Date(this.dateFrom)
        const dateDateTo = new Date(this.dateTo)
        const colTempl = (colTitle, colId, style, filtered = false) => {
          return {
            id: colId,
            name: 'col-' + colId,
            title: colTitle,
            datatype: 'Integer',
            sortindex: 1,
            maincol: 0,
            pllistdataid: null,
            datamask: '#i',
            groupid: null,
            grouptitle: '',
            plcolid: 336,
            groupparentid: null,
            groupparenttitle: '',
            width: '60px',
            colstyles: style || null,
            numcol: 1,
            pllistdatatitle: null,
            pllistflgcommon: null,
            colstyles_presentation: '',
            coltype: 0,
            flgvisible: 1,
            flgpresentvisible: 1,
            itemfield: 'offsethourss',
            filtered: filtered,
          }
        }
        // eslint-disable-next-line no-unmodified-loop-condition
        while (datefill <= dateDateTo) {
          cols.push(colTempl(this.dateDateTransformDDMM(datefill), this.dateDateTransformDDMMYY(datefill)))
          datefill.setDate(datefill.getDate() + 1)
        }
        cols.sort()
        cols.unshift(colTempl('Бонус*Смен', 'bs', 'background-color: #f5f3eb;'))
        cols.unshift(colTempl('Смен', 'отр', 'background-color: #f5f3eb;'))
        cols.unshift(colTempl('Сумма', 'сумма', 'background-color: #f5f3eb;'))
        cols.unshift(colTempl('Бонус', 'бонус', 'background-color: #f5f3eb;'))
        cols.unshift(colTempl('Оклад', 'оклад', 'background-color: #f5f3eb;'))
        cols.unshift(colTempl('Должность', 'Должность', 'background-color: #f5f3eb; width: 140px;', true))
        cols.unshift(colTempl('Т/Н', 'Личн. Номер.', 'background-color: #f5f3eb; width: 55px;'))
        const fioStyle = 'background-color: #fffae5;z-index:1; width: 140px; min-width: 140px; max-width: 140px; position: -webkit-sticky; position: sticky; left: 140px;'
        cols.unshift(colTempl('ФИО', 'ФИО', fioStyle, true))
        const cexStyle = 'background-color: #fffae5;z-index:1; width: 140px; min-width: 140px; max-width: 140px;  position: -webkit-sticky; position: sticky; left: 0;'
        cols.unshift(colTempl('Цех', 'Цех', cexStyle, true))
        return cols
      },
    },
    watch: {
      dateTo () {
        this.dateToF = this.formatDate(this.dateTo)
        const payload = {
          cookieName: 'dateToCons',
          cookieValue: JSON.stringify({ dateToF: this.dateToF, dateTo: this.dateTo }),
        }
        this.$store.dispatch('setCookies', payload)
      },
      dateFrom () {
        this.dateFromF = this.formatDate(this.dateFrom)
        const payload = {
          cookieName: 'dateFromCon',
          cookieValue: JSON.stringify({ dateFromF: this.dateFromF, dateFrom: this.dateFrom }),
        }
        this.$store.dispatch('setCookies', payload)
      },
    },
    mounted () {
      this.getOffices()
    },
    methods: {
      updateData () {
        this.menuFrom = false
        this.loadingData = true
        this.Performs = []
        this.cantUpdate = false
        const d = new Date(this.dateFrom)
        d.setDate(1)
        const dt = new Date(this.dateFrom)
        dt.setMonth(dt.getMonth() + 1)
        dt.setDate(0)
        const dateTo = new Date(dt).toISOString().substr(0, 10)
        const dateFrom = new Date(d).toISOString().substr(0, 10)
        this.dateFrom = dateFrom
        this.dateFromF = this.formatDate(this.dateFrom)
        this.dateTo = dateTo
        this.dateToF = this.formatDate(this.dateTo)
      },
      acceptTally (item) {
        if (item.statusid > 0) {
          this.$confirm({
            title: 'Утвердить список?',
            text: null,
            acceptText: 'Утвердить',
            cancelText: 'отмена',
          }).then(() => {
            const newstate = parseInt(item.statusid) + 1
            this.$store.dispatch('setTallyNewStatus', { spftsid: item.spftsid, newstatus: newstate }).then((resp) => {
              this.$store.dispatch('setSnackbar', { state: true, text: 'Отчет согласован.', color: 'success', direction: 'top center' })
              this.selectedTally.statusid = newstate
              this.selectedTallyLink.statusid = newstate
            }).catch((e) => {
              console.log(e)
            })
          }).catch(() => {
          })
        } else {
          console.log('go away')
        }
      },
      dateDateTransformDDMM (today) {
        let mm = today.getMonth() + 1 // Months start at 0!
        let dd = today.getDate()

        if (dd < 10) dd = '0' + dd
        if (mm < 10) mm = '0' + mm

        return dd + '.' + mm
      },
      dateDateTransformDDMMYY (today) {
        let mm = today.getMonth() + 1 // Months start at 0!
        let dd = today.getDate()
        const yyyy = today.getFullYear()

        if (dd < 10) dd = '0' + dd
        if (mm < 10) mm = '0' + mm

        return dd + '.' + mm + '.' + yyyy
      },
      exportTableToExcel (tableID, filename = '') {
        const dataType = 'application/vnd.ms-excel'
        const tableSelect = document.getElementById(tableID)
        const tableHTML = tableSelect.outerHTML.replace(/ /g, '%20').replaceAll('revo-grid', 'table')

        // Specify file name
        filename = filename ? filename + '.xls' : 'excel_data.xls'

        // Create download link element
        const downloadLink = document.createElement('a')

        document.body.appendChild(downloadLink)

        if (navigator.msSaveOrOpenBlob) {
          var blob = new Blob(['\ufeff', tableHTML], { type: dataType })
          navigator.msSaveOrOpenBlob(blob, filename)
        } else {
          // Create a link to the file
          downloadLink.href = 'data:' + dataType + ', ' + tableHTML
          // Setting the file name
          downloadLink.download = filename
          // triggering the function
          downloadLink.click()
        }
      },
      getOffices () {
        this.$store.dispatch('getDossiersOffices').then((x) => {
          this.DossiersOffices = x
          this.DossiersOfficesSelect = x[0].dossierofficeid
        })
      },
      groupSummTallyData (inObject, sortKey) {
        const outerObj = {}
        inObject.forEach((x) => {
          if (!outerObj[x[sortKey]]) outerObj[x[sortKey]] = []
          outerObj[x[sortKey]].push(x)
        })
        return outerObj
      },
      groupSummTallyDataMulti (inObject, sortKeys) {
        const outerObj = {}
        inObject.forEach((x) => {
          const supKey = []
          sortKeys.forEach((key) => {
            if (key == 'wstitle' && !x[key]) {
              supKey.push('-')
            } else {
              supKey.push(x[key])
            }
          })
          if (!outerObj[supKey.join('!!')]) outerObj[supKey.join('!!')] = []
          outerObj[supKey.join('!!')].push(x)
        })
        return outerObj
      },
      groupTallyData (inObject, sortKey) {
        const outerObj = {}
        for (const key in inObject) {
          outerObj[key] = this.groupResponse(inObject[key], sortKey)
        }
        return outerObj
      },
      summOffsetTime (inObject) {
        const outerObj = JSON.parse(JSON.stringify(inObject))
        const wpbObj = {}
        const wppObj = {}
        for (const key in inObject) {
          for (const inkey in inObject[key]) {
            const oo = { empfio: 'summ', wstitle: outerObj[key][inkey][0].wstitle, offsethourss: '00:00', wppayment: outerObj[key][inkey][0].wppayment, wpbonus: outerObj[key][inkey][0].wpbonus }
            inObject[key][inkey].forEach((o, i) => {
              wppObj[key] = outerObj[key][inkey][i].wppayment
              wpbObj[key] = outerObj[key][inkey][i].wpbonus
              if (o.offsethourss) {
                const a = o.offsethourss.split(':') // split it at the colons
                const aa = oo.offsethourss.split(':')
                if (a.length === 1) {
                  console.log('zzz', o)
                }
                if (a.length > 1) {
                  aa[0] = parseInt(a[0]) + parseInt(aa[0])
                  aa[1] = parseInt(a[1]) + parseInt(aa[1])
                  if (aa[1] >= 60) {
                    aa[0]++
                    aa[1] = aa[1] - 60
                  }
                  if (aa[1] == 0) {
                    aa[1] = '00'
                  }
                  oo.offsethourss = aa.join(':')
                  oo.spftsid = o.spftsid
                  oo.statusid = o.statusid
                } else {
                  oo.offsethourss = o.offsethourss
                  oo.spftsid = o.spftsid
                  oo.statusid = o.statusid
                }
              }
              if (outerObj[key][inkey].length === i + 1) {
                outerObj[key][inkey] = oo
              }
            })
          }
          const summRow = { empfio: 'summ', wstitle: outerObj[key].wstitle, offsethourss: '00:00' }
          const smen = { empfio: 'smen', wstitle: outerObj[key].wstitle, offsethourss: 0 }
          for (const inkey in outerObj[key]) {
            const a = outerObj[key][inkey].offsethourss.split(':') // split it at the colons
            const aa = summRow.offsethourss.split(':')
            if (a.length > 1) {
              aa[0] = parseInt(a[0]) + parseInt(aa[0])
              aa[1] = parseInt(a[1]) + parseInt(aa[1])
              if (aa[1] >= 60) {
                aa[0]++
                aa[1] = aa[1] - 60
              }
              if (aa[1] == 0) {
                aa[1] = '00'
              }
              smen.offsethourss++
              summRow.offsethourss = aa.join(':')
            }
          }
          outerObj[key]['оклад'] = { empfio: 'summ', offsethourss: wppObj[key] }
          outerObj[key]['бонус'] = { empfio: 'summ', offsethourss: wpbObj[key] }
          const bonusDay = Math.floor((wpbObj[key] / 26) * smen.offsethourss)
          outerObj[key].bs = { empfio: 'bs', offsethourss: bonusDay }
          outerObj[key]['сумма'] = summRow
          outerObj[key]['отр'] = smen
        }
        console.log('summ', outerObj)
        return outerObj
      },
      getTallySheets () {
        const pload = { dateTo: this.dateTo, dateFrom: this.dateFrom, dossiersofficeid: this.DossiersOfficesSelect }
        this.$store.dispatch('getSummTally', pload).then(async (resp) => {
          this.Tallys = resp
          this.Tallys.forEach((dt) => {
            if (dt.statename !== 'Р') {
              dt.offsethourss = dt.statename
            }
          })
          this.TallysData = JSON.parse(JSON.stringify(this.groupSummTallyData(this.Tallys, 'datedates')))
          this.TallysData2 = JSON.parse(JSON.stringify(this.groupSummTallyDataMulti(this.Tallys, ['wstitle', 'empfio', 'empid', 'postname'])))
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          console.log(e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      getTally (item) {
        // console.log(this.selectedTally)
        if (!this.selectedTally) {
          this.selectedTally = { spftsid: 0 }
        }
        if (this.selectedTally.spftsid !== item.spftsid) {
          this.selectedTallyLoading = false
          this.$store.dispatch('getTallysheetEmpsList', { SpfTsID: item.spftsid, DossiersOfficeID: item.dossiersofficeid }).then((resp) => {
            this.selectedTally = Object.assign({}, item)
            this.selectedTallyLink = item
            this.selectedTallyList = resp.map((items, index) => ({ ...items, index: index }))
            this.selectedTallyListSnapShot = JSON.parse(JSON.stringify(this.selectedTallyList))
            this.selectedTallyLoading = true
            // console.log(resp)
          }).catch((e) => {
            this.selectedTallyLoading = true
            console.log(e)
          })
        }
      },
      getPerformance () {
        if (this.dateTo && this.dateFrom && this.dateTo >= this.dateFrom && this.DossiersOfficesSelect) {
          this.$store.dispatch('setDataLoading', true)
          this.$store.dispatch('getSPFperformance', {
            dateTo: this.dateTo,
            dateFrom: this.dateFrom,
            dossierofficeid: this.DossiersOfficesSelect,
            ptype: 2,
          }).then((resp) => {
            this.Performs = resp
            let perf = this.groupResponse(resp, 'ptype')
            // this.templist = JSON.parse(JSON.stringify(perf))
            perf['2'].forEach((x, i) => {
              if (x.pvalue) {
                perf['1'][i].pvalue = x.pvalue
                perf['1'][i].ptype = x.ptype
              }
            })
            perf = perf['1']
            this.PerformsGroup = this.groupResponse(perf, 'wstitle')
            this.rows = []
            for (const x in this.PerformsGroup) {
              const result = {
                wsname: x,
                myRowClass: 'fit',
              }
              this.PerformsGroup[x].forEach((i) => {
                result[i.datedate.toString()] = i.pvalue
              })
              this.rows.push(result)
            }
          }).then(() => {
            this.getTallySheets()
          }).catch((e) => {
            console.log(e)
            this.$store.dispatch('setLoading', false)
          })
        }
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('-')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      groupResponse (resp, key) {
        return resp.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})
      },
    },
  }
</script>

<style>
</style>
